import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import Countdown from "../components/countdown"

const IndexPage = () => (
  <Layout>
    <Seo title="Freddy & Carolina's Wedding" />
    <section>
      <div className="relative">
        <StaticImage
          src="../images/carolina-and-freddy-index.jpg"
          alt="Carolina and Freddy looking at each other."
          quality="80"
        />
        <div className="absolute inset-x-0 w-1/2 px-0 py-1 mx-auto bg-white rounded md:p-6 md:w-2/5 bottom-10 md:bottom-24 lg:bottom-36">
          <h1 className="italic text-center md:text-4xl lg:text-6xl font-baskerville">¡Nos vamos a casar!</h1>
        </div>
      </div>
    </section>
    <section>
      <div className="container p-10 text-center md:py-24 font-baskerville">
        <h3 className="text-lg md:text-2xl">Tenemos el honor de invitarlos a nuestra boda.</h3>
        <h2 className="mt-12 mb-2 text-3xl italic md:text-5xl">23 de Abril, 2022</h2>
        <h2 className="mb-10 text-2xl md:text-3xl">4:00 PM</h2>
        <h3 className="text-lg md:text-3xl">La Quebrada</h3>
        <h3 className="text-xl italic md:text-4xl mb-1 md:mb-3">La Esperanza, Quetzaltenango</h3>
        <Countdown date={`2022-04-23T16:00:00`} />
        <h3 className="mt-2 text-lg md:text-xl">¡Hasta el gran día!</h3>

      </div>
    </section>
    <section className="py-10">
      <div className="container">
        <h3 className="mb-4 text-xl italic text-center md:text-3xl font-baskerville">Fotos de Compromiso</h3>
        <div className="grid grid-cols-3 md:grid-cols-6 grid-rows-3 md:grid-rows-3 gap-4">
          <StaticImage 
            src="../images/DSC_0023-01.jpeg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-1"
            objectPosition="88% 0%"
          />
          <StaticImage 
            src="../images/e16632b0-997a-43d8-96c1-6b72cce61d72.jpeg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-1"
          />
          <StaticImage 
            src="../images/c9ed8073-1517-4a91-9032-664738a58f3e.jpeg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-2"
          />
          <StaticImage 
            src="../images/1c6dacba-884c-4619-9177-beff731528fe.jpeg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-2"
          />
          
          <StaticImage 
            src="../images/hand-holding-ring.jpg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-1"
            objectPosition="40% 0%"
          />
          <StaticImage 
            src="../images/f22671b9-97d8-430f-a2b8-ca0475985b61.jpg"
            alt="Hand holding ring."
          />
          <StaticImage 
            src="../images/image7.jpeg"
            alt="Hand holding ring."
          />
          <StaticImage 
            src="../images/image9.jpeg"
            alt="Hand holding ring."
            className="md:col-span-2 md:row-span-1"
            objectPosition="62% 0%"
          />
          <div className="md:col-span-2 md:row-span-1 md:hidden">
            <StaticImage 
              src="../images/IMG_0723.jpeg"
              alt="Hand holding ring."
              objectPosition="40% 0%"
              className="h-full"
            />
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default IndexPage
